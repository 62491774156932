
.noCompleto {
  border-bottom: 1px solid #ff0000 !important;
}
.danger {
  color: red;
}
.text-primary {
  color: #007dbb !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-logos {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.1s;
  }
}
a {
  color: #43444a !important;
  text-decoration: underline;
}
.App-header {
  height: 100vh;
  width: 100vw;
  display: flex;

  justify-content: center;
}
.App-header-up {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(15deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.bg-success {
  background-color: #007dbb !important;
  color: white;
}
.app-card {
  border: 1px solid #fff;
  border-radius: 30px 30px 30px 30px;
  background: #fff;
}
.app-card-up {
  border: 1px solid #fff;
  border-radius: 0px 0px 30px 30px;
  background: #fff;
}
.app-button {
  background-color: #007dbb !important;
  color: #fff;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #43444a !important;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}
input {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-image: initial;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff !important;
  background-clip: padding-box;
  border: none !important;
  appearance: none;
  border-radius: 0px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.app-color {
  color: #43444a;
}
.app-click {
  color: #43444a;
  cursor: pointer;
}
.text-space {
  display: flex;
  justify-content: space-between;
}
.app-grid {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.app-center-login {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.app-background {
  background: gray;
  height: 100vh;
  background-image: url("https://www.creditomagico.com.ar/img/credito-magico-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
a {
  text-decoration: none !important;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.btn-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-grey {
  background-color: #f5f5f5;
  color: black;
}

