
  .fondo-canales{
    background-image: url(../../assets/img/telefonista.svg);
    background-size: 284px;
    background-repeat: no-repeat;
    background-position: left center;
    background-color: rgb(242 242 242);
    width: 100%;
  }

  .box-segumas{
    background: rgb(255, 255, 255);
    color:#6e6e6e;
    width: 103px;
    height: 103px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
  }
  .box-segumas:hover{
    background-color: #007dbb;
    color: #fff !important;
  }
  .box-canales{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      color: rgb(110, 110, 110);
      gap: 20px;
      padding: 40px;
  }
  .sm-canales{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 768px) {
  .sm-canales{
    gap: 50px;
    padding: 20px;
  }
  }