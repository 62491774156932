.color-app{
    color:#213450
}
.top-header{
    background: #007dbb ;
    color: #fff;
    padding: 15px;
}
.text-app{
    color:rgb(33, 52, 80);
}
.nav-header{
    display: flex;
    justify-content: space-between;
    padding: 30px;
}
.nav-img{
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-top: 10px;
}
.nav-header-items{
    display: flex;
    justify-content: space-between;
}
.nav-items{
    padding-right: 81px;
    text-transform: uppercase;
    margin: 0;
    z-index: 28;
    position: relative;
    font-family: DIM;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: -0.05px;
    color: #4a4b4c;
    font-weight: normal;
    cursor: pointer;
}
.nav-items:hover{
    color:#17a0d5
}
.columna-up{
    margin-top: -40px !important;
    z-index: 100;
  
}

.columna{
    width: 50%;
    padding: 40px 0px 0px 0px;
    margin-left: -12px;     
}
.columna-info{
    background-color: #17a0d5;
    width: 100%;  
    color: #fff;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 4.5rem;
    margin-top: -40px; 
}
.columna-info-hogar{
    width: 100%;  
    color: #fff;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 4.5rem;
    margin-top: -40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #5c0f8b;
    background-image: url('../../assets/img/edificios-background.svg');
    height: 451px; 
}
.precio-hogar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.titulo-hogar {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 50px;
    font-weight: 500;
    color: #5c0f8b;
    line-height: 50px;
    margin: 40px 0px 60px;
    padding-left: 13.5%;
}

.contenedor{
   margin-left: 40px; 
   margin-right: 40px;
}
.row-app{
    display: flex;
    flex-direction: row;
}
.foo-seguros {
    background: #eeeeef;
}
.footer {
    max-width: 1280px;
    margin: auto;
}

.box {
    width: 100%;
    background: rgb(33, 52, 80);
    color: rgb(255, 255, 255);
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    
}
.box-1 {
    width: 100%;
    background: #6e6e6e;
    color: rgb(255, 255, 255);
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.box-button{
    background-color: #fff;
    border: 1px solid rgb(33, 52, 80);
    color: rgb(33, 52, 80);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    cursor: pointer;
}
.box-button:hover{
    background-color:rgb(33, 52, 80);
    border: 1px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}
.box-cerca{
    padding: 50px;
    background: #afacac;
    color: #fff;
    cursor: pointer;
}
.box-cerca:hover{
    padding: 50px;
    background: rgb(33, 52, 80);
    color: #fff;
}
.box-cerca1{
    padding: 50px;
    background: #17a0d5;
    color: #fff;
    cursor: pointer;
}
.box-cerca1:hover{
    padding: 50px;
    background: rgb(33, 52, 80);
    color: #fff;
}
.box-cerca2{
    padding: 50px;
    background: #6e6e6e;
    color: #fff;
    cursor: pointer;
}
.box-cerca2:hover{
    padding: 50px;
    background: rgb(33, 52, 80);
    color: #fff;
}
.boton-baja{
    width: 80%;
    text-align: center;
    color: #fff;
    background-color: #17a0d5;
    font-size: 10px;
    height: 20px;
    display: flex;    
    align-items: center;
    justify-content: center;
}
.big-number {
    font-size: 140px;
    font-weight: bold;
    line-height: 140px;
}
.big-text {
    font-size: 44px;
    font-weight: bold;
    margin: 0 0 40px;
}
.check-option {
    display: flex;
    font-size: 13px;
    justify-content: center;
    gap: 15px;
}
.container-check{
    background: white;
    height: 100px;
    align-items: center;
    padding: 10px;
}
.arrow {
    border: solid #213450;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 19px;
    width: 60px;
    height: 60px;
    text-decoration: none;
    -webkit-transform: rotate(45deg);
}
.container-formulario{
    background: #eeeeef;
    padding: 60px;
}
.contenedor-planes-hogar{
    padding: 80px;
}
@media (max-width: 767px) {
    .contenedor-planes-hogar{
        padding: 30px;
    }

    .container-formulario{        
        padding: 20px;
    }
    .container, .container-sm{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .big-number{
        font-size: 5rem;
    }
    .columna-info-hogar{
        width: 100%;  
        color: #fff;
        text-align: left;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        
        margin-top: 0px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #5c0f8b;
        background-image: url('../../assets/img/edificios-background.svg');
        padding: 30px; 
    }
    .contenedor{
        margin-left: 0px; 
        margin-right: 0px;
     }
    .columna-info{
        background-color: #17a0d5;
        width: 100%;  
        color: #fff;
        text-align: left;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 2rem;
      
    }
    .row-app{
        display: flex;
        flex-direction: column;
    }
  }
  .fade-in {
    
    opacity: 1;
    transition: opacity 4.5s ease-in-out;
    display: block;
    background: #fff;
  }
  
  /* Clase para ocultar el elemento */
  .fade-out {
    opacity: 0;
    transition: opacity 4.5s ease-in-out;
    pointer-events: none; /* Para evitar interacciones con el elemento oculto */
    display: none; /* Asegúrate de que el elemento esté oculto después de la transición */
  }
  
.info-title{
    font-size: 34px;
    font-weight: bold;
    color: #fff;
}
.info-text{
    font-size: 30px;
}

.small-text{
    font-size: 10px;
}
.columna-planes{
    background: #6e6e6e;
    color: #fff;
}
.contenedor-planes{
    padding: 50px;
}

.box-pasos{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #5c0f8b;
    color: #fff;
    font-size: 34px;
    padding: 30px;
    min-height: 389px;
}
.box-pasos1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #afacac;
    color: #fff;
    font-size: 34px;
    padding: 30px;
    min-height: 389px;
}
.box-pasos2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #666666;
    color: #fff;
    font-size: 34px;
    padding: 30px;
    min-height: 389px;
}
.btn-banner {
    width: 185px;
    height: 42px;
    background-color: #17a0d5;
    line-height: 10px;
    color: #fff;
    border: 1px transparent;
}
.top {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 56px;
    font-weight: 500;
    color: #213450;
    line-height: 56px;
    margin: 0px 61px 40px;
    display: flex;
    justify-content: left;
}
.top-1 {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 56px;
    font-weight: 500;
    color: #213450;
    line-height: 56px;
    display: flex;
    justify-content: left;
}
.accordion{
    border-radius: 20px !important;
}
.accordion-item{
    background: #d8d8d8 !important;
    color:#747674 !important;
    border-radius: 20px !important;
}  
.accordion-button{
    background: #d8d8d8 !important;
    color:#5e5e5e !important;
    border-radius: 20px !important;
    border: 1px solid transparent !important;
    font-size: 19px !important;
    padding: 30px !important;
}  
.accordion-header{
    background: transparent !important;
    color:#747674 !important;
    border-radius: 20px !important;
} 
.accordion-button:focus {
    box-shadow: none !important;
}
.accordion-button:active {
    box-shadow: none !important;
}
.accordion-button:hover {
    box-shadow: none !important;
}

.btn-contact-auto {
    width: 351px;
    height: 63px;
    background-color: #17a0d5;
    color: #fff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 19px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    top: 0px;
    margin-top: 20px;
    border: 1PX solid transparent;
}
.btn-contact-auto:disabled {
    background-color: #7d7e7f;
}