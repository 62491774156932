#footer-bar {
  background-color: rgba(255, 255, 255, 0.95);
}
#footer-bar {
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background: #ffffffcf;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.04);
  height: 60px;
  height: calc(60px + (env(safe-area-inset-bottom)) * 1.1);
  display: flex;
  text-align: center;
  transition: all 250ms ease;
}

#footer-bar a:not(.btn) {
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
  color: #818181;
}
#footer-bar div {
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
  color: #818181;
}
#footer-bar .active-nav i {
  background-image: linear-gradient(to bottom, #5d9cec, #4a89dc) !important;
  color: #fff !important;
}

.footer-bar .active-nav i {
  position: absolute !important;
  width: 48px;
  height: 48px;
  left: 50%;
  margin-left: -24px;
  transform: translateY(-28px);
  border-radius: 22px;
  line-height: 48px;
}
.footer-bar-detached {
  border-radius: 20px;
  left: 10px !important;
  right: 10px !important;
  bottom: 15px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  height: 60px !important;
}
#footer-bar a:not(.btn) span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -3px;
  opacity: 0.7;
  font-family: Roboto, sans-serif !important;
}
#footer-bar div span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -3px;
  opacity: 0.7;
  font-family: Roboto, sans-serif !important;
}
#footer-bar .active-nav svg {
  background-image: linear-gradient(to bottom, #5d9cec, #4a89dc) !important;
  color: #fff !important;
}
.footer-bar .active-nav svg {
  position: absolute !important;
  width: 48px;
  height: 48px;
  left: 50%;
  margin-left: -24px;
  transform: translateY(-28px);
  border-radius: 22px;
  line-height: 48px;
}
.footer-bar .active-nav span {
  padding-top: 22px;
  font-weight: 600 !important;
  color: #1f1f1f !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.botonLateral{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 0.5px solid #fafafa;
}
.botonLateral:hover{
 background: #fafafa;
}