.parametros {
    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #f5f3f3 !important;
        background-clip: padding-box;
        border: none !important;
        appearance: none;
        border-radius: 0px !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .app-button {
        background-color: #292740 !important;
        color: #fff;
    }

}