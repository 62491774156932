.navbarseg {
    width: 100%;
    .dropdown-menu{
        border: none !important;
    }
    .dropdown-item{
        
        white-space: inherit;
    }

    .h-segumas:hover{
        color:#007dbb !important

    }
    .nav {
    
        width: 100%;
       
        color:black;
        background-color: #fff;    
        border-bottom: 1px solid #cfcfcf;
      }
      

      
      .nav__menu {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 1rem;
      }
      
      .nav__menu li {
        display: block;
        list-style-type: none;
      }
      
      .nav__menu li:first-child {
        display: block;
        margin-right: auto;
      }
      

      
      .menu__logo {
       width: 150px;
        
      }
      .icono-segumas{
        border-radius: 50%;
        width: 28px;
        height: 28px;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icono-segumas:hover{
        color:#007dbb;
        border: 1px solid #007dbb;
      }
      
      .content {
        height: 85vh;
      }
      @media only screen and (min-width: 560px) {
        .nav__toggle {
          display: none;
        }
      
        .nav__menu {
          flex-direction: row;
        }
      
        .nav__menuli {
          display: block;
        }
      }
      .btn-segumas{
        color:#fff !important;
        background-color: #007dbb !important;
        border-radius: 0px;
        width: 104.8px !important;
        height: 43.8px;
        font-size: 13.2px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.08em;
      }
      .btn-segumas:hover{       
        background-color: #fff !important; 
        color: #007dbb !important;
        border: 1px solid #007dbb;    
      }
      .link-segumas{
        color: #000 !important;      
      }
      .link-segumas:hover{
        color: #007dbb !important;        
      }
      .menu-segumas{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
      }
      .dropdown-menu{
        width: 115px !important;
        min-width: 115px !important;
      }
      .dropdown-menu:hover{
        color: #007dbb !important;  
        
      }
      .dropdown-toggle::after {
        display: none;
    }
    br{
        content: "";
        margin: -1em !important;
        display: block;
       
    }
    .search-icon {
        left: 15px;
     }
     ::placeholder {        
        font-size: 16px !important;  
        color:#000 !important;
        }
        .ps-5 {
            padding-left: 2.5rem !important;
        }
        .shadow {
          box-shadow: 0 .2rem 0.2rem rgba(0, 0, 0, .15) !important;
      }

}