.seccionautodescuento {
    background: #fb986e;
    color:#fff;
  .texto-promocion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
}