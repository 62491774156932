.chat-modal {
    position: fixed;   
    right: 0;
    height: 100%;
    width: 500px; /* Ajusta el ancho según tus necesidades */
    top: 30%;
}
.button-close{

        position: absolute;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: -70px;
        cursor: pointer;
        right: 10px;

}
.form-app{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow 
}