.SeccionBaja{  
  background-color: #fff;


      .btn-segumas{
        color:#fff !important;
        background-color: #007dbb !important;
        border-radius: 0px;
        width: 104.8px !important;
        height: 43.8px;
        font-size: 13.2px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.08em;
      }
      .btn-segumas:hover{       
        background-color: #fff !important; 
        color: #007dbb !important;
        border: 1px solid #007dbb;    
      }
}

.icono-segumas-f{
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid rgb(255, 255, 255);
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icono-segumas-f:hover{
  color:#007dbb;
  border: 1px solid #007dbb;
}
.icono-segumas-w{
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 25px;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icono-segumas-w:hover{
  color:#007dbb;
  border: 1px solid #007dbb;
}
.btn-segumas-f{
  color:#fff !important;
  background-color: #007dbb !important;
  border-radius: 0px;
  width: 150.8px !important;
  height: 43.8px;
  font-size: 13.2px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.08em;
}
.btn-segumas-f:hover{       
  background-color: #fff !important; 
  color: #007dbb !important;
  border: 1px solid #007dbb;    
}