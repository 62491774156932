.tarjeta {
  .flip-container {
    background-color: transparent;
    width: 500px;
    height: 300px;
    border-radius: 20px;
    border: 1px solid transparent;
    perspective: 1000px;
    
  }
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;

    transition: transform 1s;
    transform-style: preserve-3d;
  }
 /* .flip-container:hover .card {
    transform: rotateY(180deg);
  }*/
  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  .front {

    border-radius: 10px;
    color: #fff;
  }
  .back {
    background: rgb(48, 48, 48);
    background: linear-gradient(
      90deg,
      rgba(48, 48, 48, 1) 0%,
      rgba(80, 80, 80, 1) 35%,
      rgba(166, 166, 166, 1) 100%
    );
    border-radius: 10px;
    color: white;
    transform: rotateY(180deg);
  }
  .number {
    font-size: 35px;
  }
  .nombre {
    text-transform: uppercase;
    padding-left: 80px;
    font-size: 20px;
  }
  .cinta {
    background-color: rgb(34, 34, 34);
    height: 60px;
    width: 496px;
  }
  .code {
    padding-top: 8px;
    color: black;

    background-color: #f3f3f3;
    width: 400px;
    height: 50px;
    font-size: 1.5rem;
    display: flex;
    align-items: end;
    justify-content: end;
  }
  .hasta {
    padding-top: 10px;
    width: 400px;
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 5px;
  }
  .tipoTarjeta{
    font-size: 25px;
    margin: 10px;
  }
  .rever{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
    }