.seccionslider {
    
    background: #f2f2f2;    
    .carousel-caption {
        position: absolute;
        right: 10%;
        bottom: 40%;
        left: auto;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }
}