
.fondo-donde{
  /*  background-image: url(../../assets/img/map.svg);*/
    background-size: 284px;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: rgb(242 242 242);
    width: 100%;
  }
.boton-segumas{
    background: rgb(242 242 242);
	width: 200px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 2px #007dbb;
	color: #007dbb;
	font-size: 16px;
}
.boton-segumas:hover{
    background-color: #007dbb;
    color: #fff !important;
}

@media (max-width: 767px) {
  .box-estamos{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: rgb(55, 55, 55);
    gap: 20px;
    padding: 30px;

}

}

@media(min-width: 768px)and (max-width: 2200px){
  .box-estamos{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: rgb(55, 55, 55);
    gap: 20px;
    padding: 30px;

}

}