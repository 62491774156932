.buttonwhatsapp {
    .btnCenter {
        background: none;
        border-radius: 16px;
        bottom: 100px;
        height: 33px;
        max-height: 2px;
        min-height: 0;
        padding: 0;
        position: fixed;
        right: 2px;
        top: auto;
        visibility: visible;
        width: 399px;
        z-index: 2147483646;
    }
}