.seccionnuestros {
    .box-nuestros{
    width: 100%;
    height: 252px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    color: #007dbb;
    }
    .box-nuestros:hover{
        background: #007dbb;
        color: #fff;
    }
    .icono-hogar{
        font-size: 146px;
    }
    @media (max-width: 767px) {
 
      
      }
      
      @media(min-width: 768px)and (max-width: 2200px){

      }
}