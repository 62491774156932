.seccionadicionales {
  .adicionales-icon{
        background: #f2f2f2;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 150px;
        
  }
  .adicionales-icon:hover{
    background-color: #007dbb !important;
    color: #fff !important;
}
.adicionales-descripcion{
    background-color: #007dbb !important;
    color: #fff !important;
    font-size: 20px;
    padding: 10px;
    width: 402px;
}
}