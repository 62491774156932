.emisionauto {
    .btn-emision-auto {
        width: 351px;
        height: 63px;
        background-color: #17a0d5;
        color: #fff;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-size: 19px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        position: relative;
        top: 0px;
      
        border: 1PX solid transparent;
    }
    .btn-emision2-auto {
        width: 351px;
        height: 63px;
        background-color: #969696;
        color: #fff;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-size: 19px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        position: relative;
        top: 0px;
        
        border: 1PX solid transparent;
    }
    .RSPBprogressBar {
       /* left: 30px;*/
    }
    /* Oculta las flechas en los input de tipo número */
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

/* Restablece el estilo para Safari y Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Restablece el estilo para Internet Explorer 10-11 */
input[type="number"]::-ms-clear {
    display: none;
}
    
}