.seccionatencion {
  .icono-segumas-f:hover{
    color:#007dbb;
    background:white
  }
  .icono-segumas-w:hover{
    color:#007dbb;
    background:white
  }
 .link-atencion{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
}