.seguroauto {
    .contenedor-imagen {
        width: 100%; /* Ancho predefinido */
        height: 400px; /* Altura predefinida */
        position: relative; /* Para posicionar el fondo de carga */
      }
      .contenedor-imagen.fondo-carga::after {
        content: ''; /* Contenido pseudo-elemento */
        display: block; /* Mostrar como bloque */
        width: 100%; /* Ancho total */
        height: 100%; /* Altura total */
        background-color: #f0f0f0; /* Color de fondo */
        position: absolute; /* Posicionamiento absoluto */
        top: 0; /* Alinear arriba */
        left: 0; /* Alinear a la izquierda */
        z-index: 1; /* Colocar encima de la imagen */
      }
      .contenedor-imagen img {
        width: 100%; /* Para que la imagen ocupe todo el contenedor */
        height: 100%; /* Para que la imagen ocupe todo el contenedor */
        object-fit: cover; /* Para ajustar la imagen al tamaño del contenedor */
        position: relative; /* Para posicionar la imagen */
        z-index: 2; /* Colocar encima del fondo de carga */
      }
      .fondo-hogar{
        background-image: url(../../assets/img/mujer-auto.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgb(242 242 242);
        width: 100%;
        height: 611px;
        position: relative;
      }
      .fondo-hogar-logo{
        color: #fb986e;
        position: absolute;
        right: 10px;
        z-index: 999;
        bottom: 0px;
        font-size: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }
      .btn-segumas{
        color:#fff !important;
        background-color: #007dbb !important;
        border-radius: 0px;
        width: 333px !important;
        height: 83px;
        font-size: 21px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.08em;
      }
      .btn-segumas:hover{       
        background-color: #fff !important; 
        color: #007dbb !important;
        border: 1px solid #007dbb;    
      }
      @media(min-width: 768px)and (max-width: 1200px){
   
       }
}