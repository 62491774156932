.segurohogar {
    .accordion-item{
        background: #fff !important;
        color:#747674 !important;
        border-radius: 20px !important;
    }  
    .accordion-button{
        background: #fff !important;
        color:#5e5e5e !important;
        border-radius: 20px !important;
        border: 1px solid transparent !important;
        font-size: 19px !important;
        padding: 30px !important;
    }  
    .accordion-header{
        background: transparent !important;
        color:#747674 !important;
        border-radius: 20px !important;
    } 
    .accordion-button:focus {
        box-shadow: none !important;
    }
    .accordion-button:active {
        box-shadow: none !important;
    }
    .accordion-button:hover {
        box-shadow: none !important;
    }
    .wpb_wrapper{
        display:flex;
        flex-direction:column;
        justify-content:center
    }
     .row-products .row_product .producto_item .product_information .col-left>br{
        display:none
    }
     .row-products .row_product .producto_item .product_information .col-left img{
        position:relative;
        display:inline-block;
        margin-bottom:20px;
        height:55px
    }
    @media(max-width: 992px){
         .row-products .row_product .producto_item .product_information .col-left img{
            height:40px;
            margin:0px 0px 10px
        }
    }
     .row-products .row_product .producto_item .product_information .col-right-info{
        position:absolute;
        opacity:0
    }
     .row-products .row_product .producto_item.active{
        width:50%
    }
     .row-products .row_product .producto_item.active .product_information .col-left{
        width:20%
    }
    @media(max-width: 992px){
         .row-products .row_product .producto_item.active .product_information .col-left{
            display:none
        }
    }
     .row-products .row_product .producto_item.active .product_information .col-right-info{
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:70%;
        position:relative;
        height:100%;
        opacity:1;
        transition:all 2s
    }
    @media (max-width: 767px) {
        .container-icon{
            flex-direction: column;
        }
   
    }
   .plomeria{
        background-color:#5c0f8b;
        color:#fff;
        padding: 10px;
    }
     .cerrajeria{
        background-color:#afacac;
        color:#fff;
        padding: 10px;
    }
   .electricidad{
        background-color:#6e6e6e;
        color:#fff;
        padding: 10px;
    }
     .gas{
        background-color:#5c0f8b;
        color:#fff;
        padding: 10px;
    }
  
   .vidreria{
        background-color:#afacac;
        color:#fff;
        padding: 10px;
    }
    .sexto{
        background-color:#6e6e6e;
        color:#fff;
        padding: 10px;
    }
 .product_title{
        font-size:14px;
        font-weight:300;

        color:#fff;
        display:block;
        width:100%;
   

        font-weight:bold
    }
 .product_description{
        font-size:14px;
        font-weight:400;
        color:#fff;
         align-items: center;
       padding: 15px;
       
    }
    .container-icon{
        display: flex;
        
        align-items: center;
    }
    @media(min-width: 768px)and (max-width: 1200px){
     .product_description{
            font-size:15px
        }
  
    }
    .box-container{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .box-poliza{
        width: 33%;
        height: 132px;
        color: #fff;
        background: #926d99;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column
    }
    .box-poliza-1{
        width: 33%;
        height: 132px;
        color: #fff;
        background: #7d5299;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column
    }
    .box-poliza-2{
        width: 33%;
        height: 132px;
        color: #fff;
        background: #5c0f8b;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column
    }
    .titulo-hogar{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        color: black;
        font-style: italic;
        height: 150px;
    }
    .fondo-hogar{
        background-image: url(../../assets/img/pareja-joven-hija-acostada-cama.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgb(242 242 242);
        width: 100%;
        height: 611px;
        position: relative;
      }
      .fondo-hogar-logo{
        color: #662d91;
        position: absolute;
        left: 10px;
        z-index: 999;
        bottom: 0px;
        font-size: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }
      .columna-planes {
        background: #eeeeef;
        color: #000000;
        padding: 60px;
        height: 515px;
    }

}