@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@100;300;400&display=swap");

@font-face {
  font-family: 'DIM'; 
  src: url('./assets/Din-Font/DIN.ttf') format('truetype');
  font-weight: normal; 
  font-style: normal; 
}
body {
  font-family: 'DIM';
}
.App {
  text-align: center;
  font-family: 'DIM';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent !important;
}
.form-control:active {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent !important;
}
.form-control {
  color: #212529;
  background-color: #fff

}
.badge-success {
  text-transform: uppercase;
  padding: 2px 8px;
  font-size: 0.75em;
  background-color: #d9fbd0;
  color: #1c6c09;
  border: 1px solid #bee8b4;
}

.badge-warning {
    text-transform: uppercase;
    padding: 2px 8px;
    font-size: 0.75em;
    background-color: #e5edff;
    color: #003cc7;
    border: 1px solid #adc5ff;
}
.badge-danger{
  text-transform: uppercase;
  padding: 2px 8px;
  font-size: 0.75em;
  background-color: #ffefca;
  color: #bc3803;
  border: 1px solid #ffcc85;
}
.msj-recived {
  padding: 10px;
  background: #fff;
  margin: 10px;
  margin-right: 50%;
  width: 40%;
}
.msj-send {
  padding: 10px;
  background: #d4e3ff;
  margin: 10px;
  margin-left: 59%;
  width: 40%;
}
@media (max-width: 768px) {
  .msj-recived {
    padding: 10px;
    background: #fff;
    margin: 10px;
    margin-right: 10%;
    width: 87%;
  }
  .msj-send {
    padding: 10px;
    background: #d4e3ff;
    margin: 10px;
    margin-left: 10%;
    width: 87%;
  }  
}
.msj-scrollable {
  height: 95vh;
  overflow-y: scroll; /* Añade scroll vertical */
}
.msj-scrollable::-webkit-scrollbar {
  width: 4px; /* Ancho de la barra de desplazamiento */
}

.msj-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del fondo de la pista */
}

.msj-scrollable::-webkit-scrollbar-thumb {
  background: #888; /* Color del thumb (manija) */
  border-radius:2px; /* Borde redondeado del thumb */
}

.msj-scrollable::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb en hover */
}
.header-chat{
  background: #17a0d5;
  padding: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}