.navheader {
  .menu-item{
    cursor: pointer;
    padding: 5px;
    z-index: 1;
    
  }
  .menu-item:hover{
    background:rgba(65, 65, 65, 0.103);
    color:rgb(104, 104, 104)
  }
}