.asistentevirtual {
    .msj-scrollable {
        height: 95vh;
        overflow-y: scroll; /* Añade scroll vertical */
      }
      .msj-scrollable::-webkit-scrollbar {
        width: 4px; /* Ancho de la barra de desplazamiento */
      }
      
      .msj-scrollable::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color del fondo de la pista */
      }
      
      .msj-scrollable::-webkit-scrollbar-thumb {
        background: #888; /* Color del thumb (manija) */
        border-radius:2px; /* Borde redondeado del thumb */
      }
      
      .msj-scrollable::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color del thumb en hover */
      }
      .btn-close{
        font-size: 2rem;
      }
      .slider{
       /* aspect-ratio: 10/ 16;*/
        width: 100%;
        position: relative;
        display: flex;
        overflow: scroll;
        scroll-snap-type: x mandatory;
      }
      .slider .img{
        width: 100%;
        left: 0;
        position: sticky;
        object-fit: cover;
        border-radius: 5px;
        scroll-snap-align: center;
      }
      .estilo-x {
        font-size: 0.8rem;
      }
      /*.row>* {
        padding-left: 0px;
        padding-right: 0px;
      }*/
      .shadow{
        box-shadow: none !important;
      }
      
      /* pulse in SVG */
      svg.pulse-svg {
        overflow: visible; }
        svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
          -webkit-transform: scale(0.3);
          transform: scale(0.3);
          -webkit-transform-origin: center center;
          transform-origin: center center;
          -webkit-animation: pulse-me 3s linear infinite;
          animation: pulse-me 3s linear infinite;
          fill: #3a7afe; }
        svg.pulse-svg .second-circle {
          -webkit-animation-delay: 1s;
          animation-delay: 1s; }
        svg.pulse-svg .third-circle {
          -webkit-animation-delay: 2s;
          animation-delay: 2s; }
      
      /* pulse in CSS */
      .pulse-css,
      .pluse-css-1 {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        border-radius: 3.5rem;
        height: .4rem;
        position: absolute;
        background: #3a7afe;
        right: 5px;
        top: .6rem;
        width: .4rem; 
      }
        .pulse-css:after, .pulse-css:before,
        .pluse-css-1:after,
        .pluse-css-1:before {
          content: '';
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: -.2rem;
          background-color: #3a7afe;
          margin: auto;
          -webkit-transform: scale(0.3);
          transform: scale(0.3);
          -webkit-transform-origin: center center;
          transform-origin: center center;
          -webkit-animation: pulse-me 3s linear infinite;
          animation: pulse-me 3s linear infinite; 
        }
      
          [direction="rtl"] .pulse-css:after, [direction="rtl"] .pulse-css:before, [direction="rtl"]
          .pluse-css-1:after, [direction="rtl"]
          .pluse-css-1:before {
            left: auto;
            right: -.2rem; }
      
      .pluse-css-1 {
        background-color: #f25767; }
        .pluse-css-1:after, .pluse-css-1:before {
          background-color: #f25767; }
      
      .new {
        position: relative; 
      }
        .new:after {
          content: "";
          position: absolute;
          background-color: #f25767;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 30px;
          right: 27px;
          border: 2px solid #fff; 
        }
          @media only screen and (max-width: 767px) {
            .new:after {
              top: 26px;
              right: 9px; } 
        }
      
      @-webkit-keyframes pulse-me {
        0% {
          -webkit-transform: scale(0.3);
          transform: scale(0.3);
          opacity: 0; }
        50% {
          opacity: 0.1; }
        70% {
          opacity: 0.09; }
        100% {
          -webkit-transform: scale(3);
          transform: scale(3);
          opacity: 0; }
        }
      
      @keyframes pulse-me {
        0% {
          -webkit-transform: scale(0.3);
          transform: scale(0.3);
          opacity: 0; }
        50% {
          opacity: 0.1; }
        70% {
          opacity: 0.09; }
        100% {
          -webkit-transform: scale(3);
          transform: scale(3);
          opacity: 0; }
        }
      .social {
          display: flex;
          align-items: center;
          gap: 10px;
      }
      .perfil{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
      }
      .active:hover{
       cursor: pointer;
      }
      .row-cro{
        display: flex;
        align-content: space-between;
      }
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
      #color-button-exito {
        background-color: #0acd6e;
        border-radius: 5px;
        width: 200px;
        color: white;
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 20px;
        cursor: pointer;
        position: relative;
        height:45px
      }
      
      #color-button {
        background-color: #0d6efd;
        border-radius: 5px;
        width: 200px;
        color: white;
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 20px;
        cursor: pointer;
        position: relative;
        height:45px
      }
      
      #color-button::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        background-color: #579bff;
        transition: width 8s linear;
      }
      
      #color-button.active::before {
        width: 100%;
      }
      
      #color-button.hide {
        display: none;
      }
      .marca-lista:hover {
        background: #6c757d0f;
      }
      
      .marca-lista {
        align-content: space-between;
        border-bottom: 1px solid rgba(134,150,160,.15);
        cursor: pointer;
        display: flex;
        padding: 20px;
      }
      
      .header-app{
        background: #859aff;
        color: #fff;
        display: flex;  
        align-items: center;
        height: 60px;
        padding: 1rem;
        justify-content: flex-start;
        gap: 20px;
      }
      .msj-recived {
        padding: 10px;
        background: #fff;
        margin: 10px;
        margin-right: 50%;
        width: 40%;
      }
      .msj-send {
        padding: 10px;
        background: #d4e3ff;
        margin: 10px;
        margin-left: 59%;
        width: 40%;
      }
      @media (max-width: 768px) {
        .msj-recived {
          padding: 10px;
          background: #fff;
          margin: 10px;
          margin-right: 10%;
          width: 87%;
        }
        .msj-send {
          padding: 10px;
          background: #d4e3ff;
          margin: 10px;
          margin-left: 10%;
          width: 87%;
        }  
      }
      
      .lista-consultas {
        border-bottom: 1px solid rgba(134,150,160,.15);
        cursor: pointer;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
      }
}