.seccionpromohogar {
    background: #662d91;
    height: 386px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box-promo{
        color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    }
    
}